import CoomingSoon from "./component/ComingSoon";
import Footer from "./component/Footer";
import Navbar from "./component/Navbar";
import Timer from "./component/Timer";
import Video from "./component/Video";

function App() {
  return (
    <>
      <Navbar />

      <Video />
      <CoomingSoon name="CommingSoon" />
      <Footer />
    </>
  );
}

export default App;
